import React, { useEffect, useState } from "react";
import "../DomainReputation/domainreputation.css";
import { Box, Paper, Typography, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { TableRow, TableCell, Snackbar, Alert, TableContainer, Table, TableHead, TableBody } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        color: theme.palette.common.black,
        fontSize: 16,
        fontWeight: "bold",
        padding: "15px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        padding: "15px",
    },
}));

const ClientName = () => {
    const [step, setStep] = useState(0);
    const [domain, setDomain] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [userInput, setUserInput] = useState("");
    const [userClientNames, setUserClientNames] = useState([]);
    const [stroreClient, setStoreClient] = useState(null);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    useEffect(() => {
        findClientNames();
    }, [step]);

    const FetchDomains = (e) => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/domainslist`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    const findClientNames = () => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/findClientNames`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setUserClientNames(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    function inputHanlder(e) {
        let input = e.target.value.toLowerCase();
        setUserInput(input);
    }

    const clientNamefilterData = userClientNames.filter((d) => {
        console.log(d, "d is defined");

        if (userInput === "") {
            return d;
        } else {
            return d.clientName.toLowerCase().includes(userInput);
        }
    });

    async function handleDomainList(client) {
        console.log(client, "getting clientName");
        fetch(`${REACT_APP_SERVER_URL}/findingDomainListWithClientName?ClientName=${client}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                console.log(data, "getting data");
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    return (
        <div className="container">
            <div>
                <Box>
                    <Box sx={{ marginTop: "20px" }}>
                        <Paper
                            component="form"
                            sx={{
                                p: 2,
                                mr: 2,
                                display: "flex",
                                alignItems: "center",
                                width: "300px",
                                height: "35px",
                                boxShadow: "none",
                                border: "1px solid rgba(71, 84, 103, 1)",
                                float: "left",
                                marginBottom: "10px",
                                marginTop: "10px",
                                fontsize: "16px",
                                fontWeight: "700",
                                boxshadow: "0px 2px 1px #1018280d,",
                            }}
                        >
                            <SearchIcon sx={{ color: "#aaa" }} />
                            <InputBase
                                sx={{ flex: 1, border: "none" }}
                                placeholder="Search Clients"
                                onChange={inputHanlder}
                                inputProps={{ "aria-label": "search for projects" }}
                            />
                        </Paper>
                        <Box sx={{ marginTop: "25px" }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Client Name</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading ? (
                                            <TableBody>
                                                {[...Array(5)].map((_, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <Skeleton variant="text" width={550} height={30} />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" width={550} height={30} />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            clientNamefilterData &&
                                            clientNamefilterData.map((data, i) => (
                                                <TableRow
                                                    key={i}
                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                >
                                                    <StyledTableCell component="th" scope="row">
                                                        <Link
                                                            style={{ textDecoration: "none", color: "black" }}
                                                            to={`domain/${data.clientName}`}
                                                            onClick={() => {
                                                                setStoreClient(data.clientName);
                                                                handleDomainList(data.clientName);
                                                            }}
                                                        >
                                                            <Typography>{data.clientName}</Typography>
                                                        </Link>
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </div>

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ClientName;
