import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import axios from "axios";
const root = ReactDOM.createRoot(document.getElementById("root"));
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

axios.interceptors.request.use((request) => {
    console.log(request, "request");
    return request;
});
axios.interceptors.response.use((response) => {
    console.log(response, "res");
    if (response.config.url === `${REACT_APP_SERVER_URL}/auditAutomationProcess`) {
        const params = new URLSearchParams(response.config.data);
        const randomString = params.get("randomString");
        localStorage.setItem(randomString, JSON.stringify(response.data));
        if(response.status!==200){
            localStorage.setItem(randomString, undefined);
        }
        return response.data
    } else {
        const urlObj = new URL(response.request.responseURL);
        const params = new URLSearchParams(urlObj.search);
        const code = params.get("code");
        code && localStorage.setItem(code, JSON.stringify(response.data));
        return response;
    }
    
});
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
