import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    Container,
    Typography,
    Button,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import HomeButton from "../../img/icons/HomeButton.png";
import rectangle from "../../img/svg/rectangle.png";
import ring from "../../img/svg/ring.png";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const VerifyEmail = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState(state === null ? "" : state.msg);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(state === null ? false : state.error);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [forgetLoading, setForgetLoading] = React.useState(false);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    const submitHandler = (e) => {
        e.preventDefault();
        setForgetLoading(true);
        console.log(
            new URLSearchParams({
                email: email,
            }).toString()
        );
        fetch(`${REACT_APP_SERVER_URL}/verify-account`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    setForgetLoading(false);
                    setSuccessSnackbarOpen(true);
                    setTimeout(() => {
                        navigate("/login");
                    }, 5000);
                    return response.json();
                } else {
                    setForgetLoading(false);
                    setErrorSnackbarOpen(true);
                    return response.json();
                }
            })
            .then((data) => {
                setErrorMessage(data.msg);
                setSuccessMessage(data.msg);
            });
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box sx={{ width: "100vw" }}>
                <Link exact to="/">
                    <img src={HomeButton} alt="/" style={{ cursor: "pointer" }} />
                </Link>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={xCampaignLogo} alt="/" />
            </Box>
            <Box sx={{ width: "100vw", mt: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Container
                    maxWidth="xs"
                    sx={{
                        zIndex: 10,
                        backgroundColor: "#fff",
                        p: 5,
                        px: 4,
                        boxShadow: "0px 32px 100px rgba(50, 47, 85, 0.1)",
                        borderRadius: "65px",
                    }}
                >
                    <Typography sx={{ fontSize: "24px", fontWeight: "700", pb: 3 }}>Verify Email</Typography>
                    <Typography sx={{ fontSize: "14px", pb: 1 }}>Email</Typography>
                    <TextField
                        required
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={email}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ pb: 3 }}
                    />
                    {/* <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{ mt: 1, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB" }}
                            >
                                Send Reset Link
                            </Button> */}
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={submitHandler}
                            sx={{
                                mt: 1,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor: "#481BEB",
                            }}
                            disabled={forgetLoading}
                        >
                            {forgetLoading && <CircularProgress size={25} />}
                            {!forgetLoading && "Verify"}
                        </Button>
                    }
                    <Typography sx={{ pt: 2 }}>
                        <Typography sx={{ mr: 0.5, float: "left" }}>Back to</Typography>
                        <Link exact to="/login" style={{ color: "#3366CC" }}>
                            Login
                        </Link>
                    </Typography>
                </Container>
                <svg
                    width="47"
                    height="47"
                    viewBox="0 0 47 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "absolute", left: "530px", top: "180px", zIndex: 1 }}
                >
                    <circle cx="23.5" cy="23.5" r="23.5" fill="#FFE163" />
                </svg>
                <img src={rectangle} alt="/" style={{ position: "absolute", top: "480px", left: "460px" }} />
                <img src={ring} alt="/" style={{ position: "absolute", top: "500px", left: "1200px" }} />
            </Box>

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default VerifyEmail;
