import React, { useState } from "react";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    List,
    ListItem,
    Drawer as MuiDrawer,
    Divider,
    Container,
    MenuItem,
    Menu,
    Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import emailAcc from "../../img/icons/emailAcc.png";
import tools from "../../img/icons/tools.png";
import email1 from "../../img/icons/email1.png";
import domain from "../../img/icons/domain.png";
import blacklist from "../../img/icons/blacklist.png";
import records from "../../img/icons/records.png";
import spam from "../../img/icons/spam.png";
import avtar from "../../img/icons/avtar.png";
import blueemail from "../../img/icons/blueemail.png";
import bluedomain from "../../img/icons/bluedomain.png";
import blueblacklist from "../../img/icons/blueblacklist.png";
import bluedns from "../../img/icons/bluedns.png";
import bluespam from "../../img/icons/bluespam.png";
import bluetool from "../../img/icons/bluetool.png";
import bluecal from "../../img/icons/bluecal.png";
import globe from "../../img/icons/globe.png";
import cal from "../../img/icons/cal.png";
import emailaudit from "../../img/icons/email_audit.png";
import logo1 from "../../img/icons/logo1.png";
import jwt from "jwt-decode";
import cookie from "cookiejs";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
const drawerWidth = 244;

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});

const DashBoard = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [open, setOpen] = React.useState(true);
    const [isadmin, setAdmin] = React.useState("");
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(null);
    const [userRakesh, setUserRakesh] = useState(true);
    const [email, setEmail] = useState(false);
    const [checkUserPema, setCheckUserPema] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            pageRenderUserCheck();
            const user = jwt(isLogin);
            setAdmin(user.admin);
            setFName(user.firstname);
            setLName(user.lastname);
            setEmail(user.email);
        }
    }, [navigate]);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    };
    const handleProfileMenuOpen = (event) => {
        setProfileMenuOpen(event.currentTarget);
    };
    const handleLogoutAPI = (e) => {
        e.preventDefault();
        fetch(`${REACT_APP_SERVER_URL}/logout`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        }).then((response) => {
            if (response.status === 200) {
                console.log("User Logged out");
                navigate("/login");
                return response.json();
            } else {
                console.log("Some error");
            }
        });
    };
    const pageRenderUserCheck = () => {
        try {
            fetch(`${REACT_APP_SERVER_URL}/checkUserForDashboard`, {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.data === "rakesh@saleshive.com") {
                        setUserRakesh(true);
                        setCheckUserPema(false);
                    } else if (data.data === "pema@pema.io") {
                        setUserRakesh(false);
                        setCheckUserPema(true);
                    } else {
                        setUserRakesh(false);
                        setCheckUserPema(false);
                    }
                })
                .catch((error) => {
                    console.error("Fetch error:", error);
                });
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const currentPath = window.location.pathname.split("/")[2];

    const [states, setActiveState] = useState({
        IptIcon: currentPath === "InboxPlacement",
        DomIcon: currentPath === "DomainReputation",
        Blacklist: currentPath === "IpBlacklist",
        DNS: currentPath === "DnsRecords",
        Spam: currentPath === "spammy-word-checker",
        Tool: currentPath === "extra-tools",
        Tool1: currentPath === "find-ESP",
        Cal: currentPath === "DomainCalculator",
        SpreadSheet: currentPath === "spreadsheet",
        Integration: currentPath === "integration",
        ManualAudit: currentPath === "manualAudit",
    });

    const handleClick = (activeState) => () => {
        setActiveState((prevState) => {
            const updatedState = {
                IptIcon: activeState === "IptIcon",
                DomIcon: activeState === "DomIcon",
                Blacklist: activeState === "Blacklist",
                DNS: activeState === "DNS",
                Spam: activeState === "Spam",
                Tool: activeState === "Tool",
                Tool1: activeState === "Tool1",
                Sub: activeState === "Sub",
                Cal: activeState === "Cal",
                SpreadSheet: activeState === "SpreadSheet",
                Integration: activeState === "Integration",
                ManualAudit: activeState === "ManualAudit",
            };
            if (JSON.stringify(prevState) !== JSON.stringify(updatedState)) {
                return updatedState;
            }
            return prevState;
        });
    };
    const navLinks = [
        {
            path: "manualAudit",
            label: "Manual Audit",
            defaultIcon: records,
            activeIcon: bluedns,
            clickHandler: handleClick("ManualAudit"),
            iconCondition: "ManualAudit",
        },
        {
            path: "InboxPlacement",
            label: "Inbox Placement",
            defaultIcon: email1,
            activeIcon: blueemail,
            clickHandler: handleClick("IptIcon"),
            iconCondition: "IptIcon",
        },
        {
            path: "DomainReputation",
            label: "Domain Reputation",
            defaultIcon: domain,
            activeIcon: bluedomain,
            clickHandler: handleClick("DomIcon"),
            iconCondition: "DomIcon",
        },
        {
            path: "IpBlacklist",
            label: "Blacklist Checker",
            defaultIcon: blacklist,
            activeIcon: blueblacklist,
            clickHandler: handleClick("Blacklist"),
            iconCondition: "Blacklist",
        },
        {
            path: "DnsRecords",
            label: "DNS Records",
            defaultIcon: globe,
            activeIcon: logo1,
            clickHandler: handleClick("DNS"),
            iconCondition: "DNS",
        },
        {
            path: "spammy-word-checker",
            label: "Spam Checker",
            defaultIcon: spam,
            activeIcon: bluespam,
            clickHandler: handleClick("Spam"),
            iconCondition: "Spam",
        },
        {
            path: "DomainCalculator",
            label: "Cold Email Strategy",
            defaultIcon: cal,
            activeIcon: bluecal,
            clickHandler: handleClick("Cal"),
            iconCondition: "Cal",
        },
        {
            path: "extra-tools",
            label: "Tools",
            defaultIcon: tools,
            activeIcon: bluetool,
            clickHandler: handleClick("Tool"),
            iconCondition: "Tool",
        },
        {
            path: "find-ESP",
            label: "ESP Finder",
            defaultIcon: records,
            activeIcon: bluedns,
            clickHandler: handleClick("Tool1"),
            iconCondition: "Tool1",
        },
        {
            path: "spreadsheet",
            label: "Spam Watch",
            iconDefault: null,
            iconActive: null,
            clickHandler: handleClick("SpreadSheet"),
            iconCondition: "SpreadSheet",
            useMuiIcon: true,
            conditionalRender: checkUserPema,
        },
        {
            path: "integration",
            label: "Integration",
            defaultIcon: records,
            activeIcon: bluedns,
            clickHandler: handleClick("Integration"),
            iconCondition: "Integration",
        },
    ];
    const pathArray = [
        { path: "DnsRecords", title: "DNS Records" },
        { path: "IpBlacklist", title: "Blacklist Checker" },
        { path: "common_mailbox", title: "Common Mailbox" },
        { path: "warmup_tamplet", title: "Warmup Template" },
        { path: "email_accounts", title: "Email Accounts" },
        { path: "deliverability", title: "Deliverability" },
        { path: "integrations", title: "Integrations" },
        { path: "InboxPlacement", title: "Inbox Placement" },
        { path: "DomainReputation", title: "Domain Reputation" },
        { path: "AutoInbox", title: "Auto Inbox Placement" },
        { path: "DomainCalculator", title: "Domain Calculator" },
        { path: "teams", title: "Teams" },
        { path: "extra-tools", title: "Extra Tools" },
        { path: "subscriptions", title: "Subscriptions" },
        { path: "spammy-word-checker", title: "Spam Checker" },
        { path: "dataDashBoard", title: "DashBoard" },
        { path: "help", title: "Help" },
        { path: "spreadsheet", title: "Spread Sheet" },
    ];
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={open}
                    sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                        boxShadow: "none",
                        marginTop: "-2px",
                    }}
                >
                    <Toolbar
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: "none",
                        }}
                    >
                        {!open && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginLeft: "0!important",
                                    color: "black!important",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {open && (
                            <IconButton
                                sx={{
                                    marginRight: 5,
                                }}
                                onClick={() => {
                                    handleDrawerClose();
                                }}
                            >
                                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "84%",
                                backgroundColor: "white",
                                margin: "5px",
                                overflow: "hidden",
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                <IconButton
                                    variant="outlined"
                                    onClick={handleProfileMenuOpen}
                                    sx={{
                                        mx: 1,
                                        mb: 1,
                                        padding: "8px",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "6px",
                                        color: "#4F4F4F",
                                        height: "43px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <img
                                            src={avtar}
                                            alt="profile-img"
                                            width="32px"
                                            style={{ borderRadius: "50%" }}
                                        />
                                        <Typography
                                            sx={{
                                                color: "#4F4F4F",
                                                fontSize: "14px",
                                                fontFamily: "'HK Nova', sans-serif",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {isadmin ? <h>Admin</h> : fname}
                                        </Typography>
                                    </Stack>
                                </IconButton>
                                <Box sx={{ float: "right" }}>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={profileMenuOpen}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(profileMenuOpen)}
                                        onClose={handleProfileMenuClose}
                                        style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                                    >
                                        <MenuItem
                                            sx={{
                                                width: "100%",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                    borderbottom: "1px solid Graytext",
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        src={avtar}
                                                        alt="profile-img"
                                                        width="32px"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </div>
                                                <div style={{ padding: "18px", marginTop: "-20px" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {isadmin ? <h>Admin</h> : fname + " " + lname}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {isadmin ? <h>Admin</h> : email}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <div
                                            style={{
                                                marginTop: "2px",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                                                width: "auto",
                                            }}
                                        >
                                            <NavLink
                                                to="setting"
                                                style={{
                                                    display: "flex",
                                                    textAlign: "center",
                                                    justifyContent: "space-between",
                                                    justifyItems: "center",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    color: "black",
                                                    padding: "6px",
                                                }}
                                            >
                                                <ManageAccountsIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginLeft: "8px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        mr: "59%",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Setting{" "}
                                                </Typography>
                                            </NavLink>
                                        </div>
                                        <div style={{ width: "auto" }}>
                                            <MenuItem onClick={handleLogoutAPI}>
                                                <LogoutIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />{" "}
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Logout{" "}
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                    </Menu>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={open}
                    style={{
                        boxshadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                    }}
                >
                    <DrawerHeader
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            boxshadow:
                                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                        }}
                    >
                        <Box sx={{ paddingRight: "10%" }}>
                            <Link to="/">
                                <img
                                    src={emailaudit}
                                    alt="img"
                                    style={{
                                        height: "50px",
                                        width: "195px",
                                        top: "0",
                                        boxshadow:
                                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Link>
                        </Box>
                    </DrawerHeader>
                    <Divider />

                    <List sx={{ pl: 2, fontFamily: `'Inter', sans-serif` }}>
                        {userRakesh ? (
                            <NavLink exact to="dataDashboard" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                <ListItem
                                    sx={{
                                        backgroundColor:
                                            window.location.pathname.split("/")[2] === "Dashboard" ? "#F7F7F9" : "none",
                                        borderRadius: "50px 0px 0px 50px",
                                        borderRight:
                                            window.location.pathname.split("/")[2] === "Dashboard"
                                                ? "4px solid #7953FF"
                                                : "none",
                                        width: "100%",
                                        mb: 2,
                                    }}
                                >
                                    <img
                                        src={emailAcc}
                                        alt=""
                                        style={{
                                            marginRight: 10,
                                            height: "30px",
                                            width: "30px",
                                        }}
                                    />
                                    <Box>DashBoard</Box>
                                </ListItem>
                            </NavLink>
                        ) : (
                            <div>
                                <>
                                    {navLinks.map((link) =>
                                        !link.conditionalRender || link.conditionalRender === undefined ? (
                                            <NavLink
                                                exact
                                                to={link.path}
                                                style={{ textDecoration: "none", color: "#4F4F4F" }}
                                                key={link.path}
                                            >
                                                <ListItem
                                                    onClick={link.clickHandler}
                                                    sx={{
                                                        backgroundColor: states[link.iconCondition]
                                                            ? "#F7F7F9"
                                                            : "none",
                                                        borderRadius: "50px 0px 0px 50px",
                                                        borderRight: states[link.iconCondition]
                                                            ? "4px solid #7953FF"
                                                            : "none",
                                                        width: "100%",
                                                        mb: 2,
                                                        fontSize: "13px",
                                                        color: states[link.iconCondition] ? "blue!important" : "none",
                                                    }}
                                                >
                                                    {link.useMuiIcon ? (
                                                        <ScheduleSendIcon
                                                            style={{
                                                                height: "30px",
                                                                width: "30px",
                                                                color: states["SpreadSheet"] ? "#7953FF" : "inherit",
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={
                                                                states[link.iconCondition]
                                                                    ? link.activeIcon
                                                                    : link.defaultIcon
                                                            }
                                                            alt={link.label}
                                                            style={{ height: "30px", width: "30px" }}
                                                        />
                                                    )}
                                                    <Box sx={{ marginLeft: "12px" }}>{link.label}</Box>
                                                </ListItem>
                                            </NavLink>
                                        ) : null
                                    )}
                                </>
                            </div>
                        )}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                        backgroundColor: "#FBFBFB",
                    }}
                >
                    <Container sx={{ pt: 2 }}>
                        <Box>
                            {pathArray
                                .filter((item) => item.path === currentPath) // Filter array to find the matching path
                                .map((item) => (
                                    <Typography
                                        key={item.path}
                                        color="#000"
                                        sx={{
                                            float: item.path === "InboxPlacement" ? "center" : "left",
                                            fontSize: item.path === "InboxPlacement" ? "35px" : "24px",
                                            lineHeight: "36px",
                                            fontWeight: "700",
                                            fontStyle: "normal",
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                ))}
                        </Box>
                        <Box sx={{ py: 10 }}>
                            <Outlet />
                        </Box>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default DashBoard;
