import React, { useState } from "react";
import { Link } from "react-router-dom";
import XAudit from "../img/icons/xaudit1.png";

const NotFound = () => {
    const [hoverMessage, setHoverMessage] = useState("Click to go back home!");

    const handleMouseEnter = () => {
        setHoverMessage("Are you lost?");
    };

    const handleMouseLeave = () => {
        setHoverMessage("Click to go back home!");
    };

    return (
        <div style={styles.container}>
            <div>
                <img src={XAudit} alt="Audit not found" style={styles.image} />
            </div>
            <h1 style={styles.title}>Looking for something?</h1>
            <p style={styles.subtitle}>
                We're sorry. The Web address you entered is not a functioning page on our site.
            </p>

            <Link to="/" style={styles.link} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {hoverMessage}
            </Link>
        </div>
    );
};

const styles = {
    container: {
        textAlign: "center",
        padding: "50px",
        fontFamily: "Arial, sans-serif",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
    },
    subtitle: {
        fontSize: "16px",
        marginBottom: "20px",
    },

    link: {
        color: "#007BFF",
        textDecoration: "none",
        fontSize: "18px",
        fontWeight: "bold",
    },
};

export default NotFound;
