import React from "react";
import Dialog from "@mui/material/Dialog";
import { Alert, Box, Button, CircularProgress, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { useState } from "react";
import jwt from "jwt-decode";
import cookie from "cookiejs";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { CardContent } from "@material-ui/core";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Setting(props) {
    const user = jwt(cookie.get("token"));
    const [showPasswordToggle, setShowPasswordToggle] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const [successMsg, setsuccessMsg] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setFirstName(user.firstname);
        setLastName(user.lastname);
    }, []);

    const changeUserNameFunction = () => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/update-profile`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                fname: firstName,
                lname: lastName,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    console.log("Success");
                    setsuccessMsg(response.msg);
                    setChangePasswordSuccess(true);
                    return response.json();
                } else if (response.status === 202) {
                    setLoading(false);
                    console.log("token expired");
                    seterrorMsg(response.msg);
                    setErrorSnackbarOpen(true);
                } else {
                    console.log("some error");
                    setLoading(false);
                    setErrorSnackbarOpen(true);
                    return response.json();
                }
            })
            .then((data) => {
                if (data.status === 500) {
                    seterrorMsg(data.msg);
                    return;
                }
                setLoading(false);
                setsuccessMsg(data.msg);
                setChangePasswordSuccess(true);
                console.warn("data collected", data);
                props.User(data.data);
            })
            .catch((error) => console.log(error));
    };

    function validatePW(str) {
        if (str.length <= 5) {
            return false;
        }

        if (!/[A-Z]/.test(str)) {
            return false;
        }
        if (!/[a-z]/.test(str)) {
            return false;
        }
        return true;
    }

    const changePasswordNameFunction = () => {
        setLoading(true);
        if (validatePW(newPassword) === true) {
            if (newPassword !== confirmPassword) {
                setErrorSnackbarOpen(true);
                seterrorMsg("Confirm password and new password must be same ");
                setLoading(false);
            } else {
                fetch(`${REACT_APP_SERVER_URL}/update-password`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    method: "POST",
                    body: JSON.stringify({
                        password: newPassword,
                    }),
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setLoading(false);
                            setOpen(false);
                            console.log("Success");
                            setsuccessMsg(response.msg);
                            setChangePasswordSuccess(true);
                            return response.json();
                        } else if (response.status === 202) {
                            setLoading(false);
                            console.log("token expired");
                            seterrorMsg(response.msg);
                            setErrorSnackbarOpen(true);
                        } else {
                            console.log("some error");
                            setLoading(false);
                            seterrorMsg(response.msg);
                            setErrorSnackbarOpen(true);
                        }
                    })
                    .then((data) => {
                        setLoading(false);
                        setsuccessMsg(data.msg);
                        setChangePasswordSuccess(true);
                        console.warn("data collected", data);
                    })
                    .catch((error) => console.log(error));
            }
        } else {
            setErrorSnackbarOpen(true);
            seterrorMsg("Password Does Not Match Requirments");
            setLoading(false);
        }
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleChangePasswordSuccessSnackbar = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setChangePasswordSuccess(false);
    };

    return (
        <>
            <div>
                {loading ? (
                    <Box sx={{ width: "100%", height: "400px" }}>
                        <CircularProgress />
                        &nbsp;updating.....
                    </Box>
                ) : (
                    <>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 3, pt: 1 }} width="100%">
                                <Box>
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            marginTop: "10px",
                                        }}
                                    >
                                        Change password
                                    </Typography>
                                    <TextField
                                        id="standard-search"
                                        label="New password"
                                        type={!showPasswordToggle ? "password" : "text"}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        variant="standard"
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    />
                                    <TextField
                                        id="standard-search"
                                        label="Confirm password"
                                        type={!showPasswordToggle ? "password" : "text"}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        variant="standard"
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    />
                                    <br></br>
                                    <br></br>
                                    <li>Password length must be Greater than 5</li>
                                    <li>Must have one uppercase character</li>
                                    <li>Must have one lowercase character</li>
                                    <Checkbox
                                        sx={{ margin: "20px auto" }}
                                        onClick={() => setShowPasswordToggle(!showPasswordToggle)}
                                    />{" "}
                                    Show password
                                    <Button sx={{ float: "right", margin: 3 }} onClick={changePasswordNameFunction}>
                                        Change Password
                                    </Button>
                                </Box>
                            </Grid>
                        </Dialog>
                        <Box sx={{ width: "100%", borderRadius: "10px" }}>
                            <Typography
                                color="#000"
                                sx={{
                                    fontSize: "30px",
                                    lineHeight: "36px",
                                    fontWeight: "600",
                                    fontStyle: "normal",
                                    textAlign: "center",
                                }}
                            >
                                Setting
                            </Typography>

                            <Box sx={{ width: "100%", padding: "20px" }}>
                                <CardContent
                                    style={{
                                        width: "60%",
                                        justifyContent: "center",
                                        borderRadius: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 2px 2px 6px 2px",
                                        marginLeft: "20%",
                                    }}
                                >
                                    <Box>
                                        <Typography sx={{ textAlign: "center", fontSize: "20px", fontWeight: "700" }}>
                                            Profile Name
                                        </Typography>
                                    </Box>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 1, pt: 5 }}>
                                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>Name</Typography>
                                        <Box sx={{ display: "flex" }}>
                                            <TextField
                                                required
                                                placeholder="Name"
                                                type="name"
                                                onChange={(e) => setFirstName(e.target.value)}
                                                name="name"
                                                value={firstName}
                                                fullWidth
                                                sx={{ pb: 1, m: 1 }}
                                            />
                                            <TextField
                                                required
                                                placeholder="Last"
                                                type="name"
                                                name="name"
                                                onChange={(e) => setLastName(e.target.value)}
                                                value={lastName}
                                                fullWidth
                                                sx={{ pb: 1, m: 1 }}
                                            />
                                        </Box>
                                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>Email</Typography>
                                        <TextField
                                            required
                                            placeholder="Email"
                                            type="email"
                                            name="email"
                                            value={user.email}
                                            disabled
                                            fullWidth
                                            sx={{ pb: 1, m: 1 }}
                                        />
                                        <Button onClick={changeUserNameFunction}>Update Profile</Button>
                                        {/* <br></br> */}
                                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>Password</Typography>
                                        <TextField
                                            required
                                            placeholder="password"
                                            type="password"
                                            name="email"
                                            aria-disabled
                                            value={"12345"}
                                            fullWidth
                                            sx={{ m: 1 }}
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                        />
                                    </Grid>
                                </CardContent>
                            </Box>
                        </Box>
                    </>
                )}
            </div>

            {/* Yaha se alert box ka code h  */}
            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMsg}
                </Alert>
            </Snackbar>

            <Snackbar
                open={changePasswordSuccess}
                autoHideDuration={6000}
                onClose={handleChangePasswordSuccessSnackbar}
            >
                <Alert
                    onClose={handleChangePasswordSuccessSnackbar}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {successMsg}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Setting;
