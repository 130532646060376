import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "./components/Dashboard/DashBoard";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import VerifyAccount from "./components/VerifyAccount";
import ClientName from "./components/ClientName/Clientname";
import DnsRecords from "./components/Dns Records/DnsRecords";
import IpBlacklist from "./components/IpBlacklist/IpBlacklist";
import IpBlacklistDetails from "./components/IpBlacklistDetails/IpBlacklistdetails";
import DomainReputation from "./components/DomainReputation/DomainReputation";
import InboxPlacement from "./components/InboxPlacement/Inboxplacement";
import ReputationFeatures from "./components/ReputationFeatrues.js/ReputationFeatures";
import InboxTest from "./components/Inboxtest/Inboxtest";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import ResetPasswordRedirect from "./components/ForgotPassword/ResetPasswordRedirect";
import Setting from "./components/Dashboard/Setting";
import WarmupTamplet from "./components/Admin pages/WarmupTamplet";
import VerifyEmail from "./components/ForgotPassword/VerifyEmail";
import AutoInboxTest from "./components/AutoinboxTest/Autoinboxtest";
import DomainCalculator from "./components/Domaincalculator/Domaincalculator";
import Teams from "./components/Teams/Teams";
import Success from "./components/Subscriptions/Success";
import Failure from "./components/Subscriptions/Failure";
import Spam from "./components/SpamChecker/Spam";
import ExtraTools from "./components/Extra tools/ExtraTools";
import FindEsp from "./components/FindEsp/FindEsp";
import SpreadSheet from "./components/SpreadSheet/Spreadsheet";
import DataDashboard from "./components/DataDashboard/DataDashboard";
import DomainName from "./components/ClientName/DomainName";
import AuditReport from "./components/AuditReport/AuditReport";
import GoogleRedirect from "./components/SpreadSheet/GoogleRedirect";
import BccDetails from "./components/BccDetails/BccDetails";
import OutlookRedirect from "./components/IntegrationModule/OutlookRedirect";
import IntegrationModule from "./components/IntegrationModule/integration";
import ExampleDashboard from "./components/Examplefile/file1";
import BccComponent from "./components/Example1/ActionBcc";
import ManualAudit from "./components/manualAudit/ManualAudit";
import ManualAccordian from "./components/manualAudit/ManualAccordian";
import CheckReport from "./components/AuditReport/CheckReport";
import NotFound from "./components/NotFound";

const App = () => {
    const [user, setUser] = useState("");
    const value = (user) => {
        setUser(user);
    };
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/login" element={<Login User={value} />} />
                <Route exact path="/forgotPassword" element={<ForgotPassword />} />
                <Route exact path="/resetPassword" element={<ResetPassword />} />
                <Route exact path="/verifyemail" element={<VerifyEmail></VerifyEmail>} />
                <Route exact path="/reset-password/:id" element={<ResetPasswordRedirect />} />
                <Route exact path="/verify-account/:id" element={<VerifyAccount />} />
                <Route exact path="/test/:id" element={<InboxTest></InboxTest>} />
                <Route
                    exact
                    path="/dashboard/features/:domain/:clientName"
                    element={<ReputationFeatures></ReputationFeatures>}
                />
                <Route exact path="/check/:code" element={<IpBlacklistDetails></IpBlacklistDetails>} />
                <Route exact path="/auto-test" element={<AutoInboxTest></AutoInboxTest>} />
                <Route exact path="/success" element={<Success />} />
                <Route exact path="/failure" element={<Failure />} />
                <Route exact path="/googleredirect" element={<GoogleRedirect />} />
                <Route exact path="/outlookredirect" element={<OutlookRedirect />}></Route>

                <Route path="dashboard" element={<DashBoard user={user} />}>
                    <Route path="IpBlacklist" element={<IpBlacklist />} />
                    <Route path="warmupTamplet" element={<WarmupTamplet />} />
                    <Route path="setting" element={<Setting User={value} user={user} />} />
                    <Route path="DomainReputation" element={<DomainReputation />}>
                        <Route path="" element={<ClientName />} />
                        <Route path="domain/:clientName" element={<DomainName />} />
                    </Route>
                    <Route path="DnsRecords" element={<DnsRecords />} />
                    <Route path="subscriptions" element={<Subscriptions />} />
                    <Route path="InboxPlacement" element={<InboxPlacement />} />
                    <Route path="DomainCalculator" element={<DomainCalculator></DomainCalculator>}></Route>
                    <Route path="spammy-word-checker" element={<Spam></Spam>}></Route>
                    <Route path="extra-tools" element={<ExtraTools></ExtraTools>}></Route>
                    <Route path="find-ESP" element={<FindEsp></FindEsp>}></Route>
                    <Route path="teams" element={<Teams></Teams>}></Route>
                    <Route path="spreadsheet" element={<SpreadSheet></SpreadSheet>}></Route>
                    <Route path="dataDashboard" element={<DataDashboard></DataDashboard>}></Route>
                    <Route path="integration" element={<IntegrationModule></IntegrationModule>}></Route>
                    <Route path="audit-report" element={<AuditReport></AuditReport>}></Route>
                    <Route path="bcc-details" element={<BccDetails></BccDetails>}></Route>
                    <Route path="check-report" element={<CheckReport></CheckReport>}></Route>
                    <Route path="example-file" element={<ExampleDashboard></ExampleDashboard>}></Route>
                    <Route path="example-bccFile" element={<BccComponent></BccComponent>}></Route>
                    <Route path="manualAudit" element={<ManualAudit></ManualAudit>}></Route>
                    <Route path="manualAudit/:selectedSender" element={<ManualAccordian />}></Route>
                </Route>
                <Route exact path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default App;
