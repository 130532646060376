import React, { useEffect, useState } from "react";
import "./spam.css";
import TextField from "@mui/material/TextField";
import { PieChart } from "@mui/x-charts/PieChart";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EngData from "./englishSpam.json";
import GerData from "./germanSpam.json";
import { Box, Card, Typography, CardContent } from "@mui/material";

function Spam() {
    const [Language, setLanguage] = useState("EN");
    const [spam, setSpam] = useState(0);
    const [notSpam, setNotSpam] = useState(0);
    const [input, setInput] = useState("");
    const [score, setScore] = useState("");
    const [value, setValue] = useState("");
    const [total, setTotal] = useState("");
    const [sWords, setSpamWords] = useState("");
    const [highlight, setHightlight] = useState([]);
    const [text, setText] = useState("");
    const [highlighted, setHighlighted] = useState("");
    const [lang, setLang] = useState([]);
    const data = [
        { label: "Spam", value: spam },
        { label: "Not spam", value: notSpam },
    ];

    useEffect(() => {
        if (Language === "EN") {
            setLang(EngData.spamWords);
            highlightSpamWords(EngData.spamWords);
            setHightlight(EngData.spamWords);
        } else {
            setLang(GerData.data);
            highlightSpamWords(GerData.data);
            setHightlight(GerData.data);
        }
    });

    const onChange = (value) => {
        const inputText = value;
        const keywords = lang;
        const regex = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");
        const highlighted = inputText.replace(regex, (match) => {
            return `<span class="highlight">${match}</span>`;
        });
        setHighlighted(highlighted);
        setText(inputText);
        setInput(value);
        setValue(value);
    };

    function highlightSpamWords(spamWords) {
        const totalWords = input.trim().split(/\s+/);
        const regex = new RegExp(`\\b(${spamWords.join("|")})\\b`, "gi");
        const numSpamWords = input.match(regex) || [];
        if (totalWords.length === 1 && totalWords[0] === "") {
            totalWords.pop();
        }
        if (numSpamWords[numSpamWords.length - 1] === "") {
            numSpamWords.pop();
        }
        const spamScore = numSpamWords.length / totalWords.length;
        console.log(spamScore);
        setTotal(totalWords.length);
        setSpamWords(numSpamWords.length);
        let spamScoreTextValue = "";
        if (spamScore >= 0.1) {
            spamScoreTextValue = "Poor";
        } else if (spamScore >= 0.05) {
            spamScoreTextValue = "Good";
        } else {
            spamScoreTextValue = "Excellent";
        }
        setScore(spamScoreTextValue);
        setSpam(Math.round(spamScore * 100));
        setNotSpam(Math.round((1 - spamScore) * 100));
    }

    const handleChange = (event) => {
        setText("");
        setHighlighted("");
        setLanguage(event.target.value);
    };

    return (
        <Box sx={{ width: "100%", marginTop: "-5px", overflow: "auto" }}>
            <Box sx={{ textAlign: "center" }}>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>Spam Checker</span>
                <br />
                <span
                    style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                    }}
                >
                    Discover all DNS records for a domain with ease. From A to TXT, view various record types
                    effortlessly. Enter the domain name, choose a record type from the dropdown.
                </span>
            </Box>
            <br />

            <Box sx={{ display: "flex", marginLeft: "-14px", marginTop: "5px" }}>
                <CardContent sx={{ marginTop: "-5px" }}>
                    <Card sx={{ padding: "8px", display: "flex" }}>
                        <Typography>Words</Typography>
                        <Typography sx={{ marginLeft: "10px", fontWeight: "bolder" }}>{total}</Typography>
                    </Card>
                </CardContent>
                <CardContent sx={{ marginTop: "-5px" }}>
                    <Card sx={{ padding: "8px", display: "flex" }}>
                        <Typography>Spam</Typography>
                        <Typography sx={{ marginLeft: "10px", fontWeight: "bolder" }}>{sWords}</Typography>
                    </Card>
                </CardContent>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginLeft: "66%", marginTop: "-2px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Select language</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={Language}
                        onChange={handleChange}
                        label="Age"
                    >
                        <MenuItem value="EN">English</MenuItem>
                        <MenuItem value="DE">German</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ display: "flex" }}>
                {/* sx={{ maxWidth: 850, minWidth: 850, border: '1px solid black', maxHeight: 400, minHeight: 400, padding: '10px', borderRadius: '8px', fontSize: '18px', overflow: 'auto' }} */}
                <Box sx={{ width: "68%" }}>
                    {/*  {<HighlightWithinTextarea
                        value={value}
                        highlight={highlight}
                        onChange={onChange}
                    />} */}
                    <Box
                        sx={{
                            width: "95%",
                            padding: "20px",
                            background: "rgba(237, 244, 251, 1)",
                            borderRadius: "5px",
                        }}
                    >
                        <TextField
                            placeholder="Enter your content here"
                            multiline
                            rows={10}
                            value={text}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            sx={{ width: "100%", fontSize: "20px", backgroundColor: "white" }}
                        ></TextField>
                    </Box>
                </Box>
                <Box sx={{ width: "30%" }}>
                    <Typography sx={{ textAlign: "center", marginTop: "5%", fontSize: "18px", fontWeight: "bolder" }}>
                        Score:{score}
                    </Typography>
                    <PieChart
                        sx={{ marginTop: "10%px", marginRight: "20%" }}
                        colors={["red", "green"]}
                        series={[
                            {
                                startAngle: -360,
                                endAngle: 90,
                                data,
                                cx: 200,
                                cy: 95,
                                outerRadius: 100,
                            },
                        ]}
                        height={500}
                        width={350}
                    ></PieChart>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "65%",
                    background: "rgba(237, 244, 251, 1)",
                    padding: "15px",
                    borderRadius: "5px",
                    marginTop: "-17%",
                }}
            >
                <Box
                    sx={{
                        width: "98%",
                        border: "1px solid gray",
                        maxHeight: 280,
                        minHeight: 280,
                        padding: "10px",
                        borderRadius: "4px",
                        fontSize: "16px",
                        overflow: "auto",
                        marginLeft: "10px",
                        backgroundColor: "white",
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: highlighted }} style={{ whiteSpace: "pre-line" }} />
                </Box>
            </Box>
        </Box>
    );
}

export default Spam;
