import React, { useState } from "react";
import { CircularProgress, Box, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GoogleRedirect = (props) => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");
    const appType = queryParameters.get("state");
    const error = queryParameters.get("error");

    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    React.useEffect(() => {
        if (error !== null) {
            navigate("/dashboard/spreadsheet", { state: { msg: error, status: 201 } });
        } else {
            fetch(`${REACT_APP_SERVER_URL}/auth/googleOnboard?code=${code}&appType=${appType}`, {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            })
                .then((response) => {
                    return response.json();
                })
                .then((d) => {
                    if (d.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(d.message);
                    }
                    setErrorSnackbarOpen(true);
                    setErrorMessage(d.message)
                })
                .then(() => {
                    navigate("/dashboard/integration");
                })
                .catch((error) => console.log(error));
        }
    }, []);
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                <CircularProgress />
            </Box>

            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default GoogleRedirect;
