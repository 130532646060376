import React, { useState, useEffect, useRef } from "react";
import inbox from "../../img/images/inbox.png";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import "./Audit.css";
import { Card, CardContent, Avatar, CardActionArea} from "@material-ui/core";
import DNSOverviewChart from "../manualAudit/AuditOverview/DNSOverViewChart";
import {
    Box,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    IconButton,
    Snackbar,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Skeleton from "@mui/material/Skeleton";
import _ from "underscore";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate, Link } from "react-router-dom";
import ActionComponent from "./ActionComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { serviceProviders } from "../common/ServiceProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

ChartJS.register(ArcElement, Tooltip, Legend);

const AuditReport = () => {
    const contentRef = useRef(null);
    const [overflow, setOverflow] = useState("hidden");
    const intervalRef = React.useRef(null);
    const [progress, setProgress] = useState(0);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({
        subject: "Subject 1",
        originalContent: "Hi this is my content",
    });
    const [overview, setOverview] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [domainBlacklist, setDomainBlacklist] = useState([]);
    const [ipBlacklist, setIpBlacklist] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [randomStringForProcess, setRandomStringForProcess] = useState("");
    const [overallScore, setOverallScore] = useState(0);
    const [selectedPanel, setSelectedPanel] = useState("Overview");
    const [iptCounts, setIptCounts] = useState({});
    const [dnsData, setDnsData] = useState({});
    const [count, setCount] = useState(0);
    const [progressStatus, setProgressStatus] = useState("Processing...");
    const progressRef = useRef(progress);
    const processRef = useRef(randomStringForProcess);
    const navigate = useNavigate();

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= 150;
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += 150;
        }
    };

    const espCount = [];
    for (let key in iptCounts) {
        serviceProviders.forEach((provider)=>{
            if ((key === provider.service) && iptCounts[key] > 0) {
            const found = espCount.find((data) => data.icons === provider.icon);
            found ? (found[provider.title] = iptCounts[key]) : espCount.push({ [provider.title]: iptCounts[key], icons: provider.icon });
        
    }})
    }
    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "--",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "--",
            description: "Per day warmup volume",
        },
    ];
    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };

    useEffect(() => {
        if (randomStringForProcess) {
            // First call after 7 seconds
            const initialTimeout = setTimeout(() => {
                getAuditProgress(); // Call immediately after the initial delay

                // Set up interval to call every 5 seconds
                intervalRef.current = setInterval(() => {
                    getAuditProgress();
                }, 5000);
            }, 10000);

            return () => {
                clearTimeout(initialTimeout); // Cleanup timeout
                clearInterval(intervalRef.current); // Stop the interval
            };
        }
    }, [randomStringForProcess]);

    const getAuditProgress = async () => {
        try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/getAuditProgress?randomString=${randomStringForProcess}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Data-------->", data);
                    if (data && data.progress !== undefined && data.progress !== null) {
                        setProgress(data.progress);
                        setProgressStatus(data.status);
                    }

                    if (data.progress >= 100 || data.status === "Fail") {
                        clearInterval(intervalRef.current);
                        setProgress(data.progress);
                        setLoader(false);
                    }
                });
        } catch (error) {
            if (error.message.includes("Failed to fetch")) {
                console.error("Connection refused or network error");
                setLoader(false);
                setSnackbarMessage("Connection refused or network error");
                setSnackbarOpen(true);
                setTimeout(() => navigate("/dashboard/spreadsheet"), 2000);
            } else {
                console.error("Error fetching audit progress:", error.message);
            }
        }
    };
    console.log(progress, "progressConsole");

    function LinearProgressWithLabel(props) {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        style={{
                            height: 10, // **Height of the progress bar** (changed)

                            borderRadius: 5, // **Rounded corners** (changed)
                            "& .MuiLinearProgress-bar": {
                                borderRadius: 5, // **Rounded corners of the progress bar fill** (changed)
                            },
                            color: "#fff",
                        }}
                    />
                </Box>
                <Box style={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const queryParams = new URLSearchParams(window.location.search);
    let fromDate = queryParams.get("fromDate");
    let toDate = queryParams.get("toDate");
    let domainName = queryParams.get("domainName");

    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    const handleComponentData = async (results) => {
        for (let i = 0; i < results.length; i++) {
            if (results[i].dnsAuditResult) {
                setDnsData({
                    SPF: results[i].dnsAuditResult.spf,
                    DMARC: results[i].dnsAuditResult.dmarc,
                    DKIM: results[i].dnsAuditResult.dkim,
                });
            } else if (results[i].domainListedZones) {
                const blackList = results[i].domainListedZones?.listed.map((zone) => ({
                    name: zone.zone,
                    url: zone.url,
                }));
                setDomainBlacklist(blackList);
            } else if (results[i].ipListedZones) {
                const iplistDomains = results[i].ipListedZones?.listed
                    .filter((zone) => ({ name: zone.url }))
                    .map((zone) => ({ name: zone.zone, url: zone.url }));
                setIpBlacklist(iplistDomains);
            } else if (results[i].templates) {
                console.log("inside templates");
                let templateList = [];
                for (let j = 0; j < results[i].templates.length; j++) {
                    if (results[i].templates[j]) {
                        let arr = [];
                        for (let k = 0; k < results[i].templates[j].reasons; k++) {
                            arr.push({
                                "Without Externallink": results[i].templates[j].reasons[k]
                                    .isSpammycontentWithoutExternalLink
                                    ? true
                                    : false,
                                "Without Signature": results[i].templates[j].reasons[k].isSpammycontentWithoutSignature
                                    ? true
                                    : false,
                                "Without Images": results[i].templates[j].reasons[k].isSpammycontentWithoutImages,
                            });
                        }
                        templateList.push({
                            id: i,
                            subject: results[i].templates[j].originalTemplate.subject,
                            originalContent: results[i].templates[j].originalTemplate.content,
                            refinedContent: results[i].templates[j].refinedTemplate.content,
                            name: `Template - ${j + 1}`,
                            reasons: arr,
                        });
                    }
                }
                setTemplates(templateList);
                setSelectedTemplate(templateList[0]);
            } else if (results[i].overAllScore) {
                setOverallScore(Math.floor(results[i].overAllScore));
            } else if (results[i].iptCounts) {
                setOverview([
                    { title: "Inbox Count", count: results[i].iptCounts.inbox },
                    { title: "Spam Count", count: results[i].iptCounts.spam },
                ]);

                setIptCounts(results[i].iptCounts);
            }
        }
        setLoader(false);
        setLoading(false);
    };
    const fetchAuditAutomation = async () => {
        setLoader(true);
        var randomString;
        if (!localStorage.getItem(domainName)) {
            randomString = generateRandomString(10);

            setRandomStringForProcess(randomString);
            console.log("not in local", randomString);
            try {
                console.log("inside fetchAuditAutomation");
                const response = await axios.post(
                    `${REACT_APP_SERVER_URL}/auditAutomationProcess`,
                    new URLSearchParams({
                        fromDate: fromDate,
                        toDate: toDate,
                        domainName: domainName,
                        randomString: randomString,
                    }), // This is the second argument (body)
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        withCredentials: "include",
                    }
                );
                console.log(response, "json is comming");

                if (response.status < 200 || response.status >= 300) {
                    console.log("I am throwing", response.status);
                    throw new Error(response.statusText || "Unknown error occurred");
                }

                const results = response.data.results;
                handleComponentData(results);
            } catch (error) {
                setLoader(false);
                setLoading(false);
                navigate("/dashboard/spreadsheet");
                console.error(`\nError occurred while regenerating audit`, error);
            }
        } else {
            randomString = localStorage.getItem(domainName);
            setRandomStringForProcess(randomString);
            setCount(1);
        }
    };
    useEffect(() => {
        if (!loader && count <= 5 && count > 0) {
            const intervalId = setInterval(() => {
                console.log("I am in interval", count);
                setCount((prevCount) => prevCount + 1);
                // setCircularProgress(true);
                const checkRecords = localStorage.getItem(localStorage.getItem(domainName))
                    ? JSON.parse(localStorage.getItem(localStorage.getItem(domainName)))
                    : false;
                console.log(checkRecords, "checkRecordss");
                if (checkRecords) {
                    console.log(checkRecords, "checkRecords");
                    // setCircularProgress(false);
                    handleComponentData(checkRecords.data.results);
                    setCount(5);
                }
            }, 3000); // Interval of 1 second

            // Clear interval after 5 counts
            if (count >= 5) {
                clearInterval(intervalId);
            }
            if (count >= 5 && !localStorage.getItem(localStorage.getItem(domainName))) {
                navigate(
                    `/dashboard/check-report?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}&domainName=${domainName}`
                );
            }

            // Cleanup function to clear interval when component unmounts
            return () => clearInterval(intervalId);
        }
    }, [count, !loader]);

    useEffect(() => {
        progressRef.current = progress;
        processRef.current = randomStringForProcess;
    }, [progress, randomStringForProcess]);
    useEffect(() => {
        fetchAuditAutomation();
        return () => {
            const currentProgress = progressRef.current;
            const currentProcess = processRef.current;

            console.log(currentProgress, "progress");
            if (currentProgress < 100) {
                console.log("I am in return");
                localStorage.setItem(domainName, currentProcess);
            }
            if (currentProgress >= 100) {
                console.log("I am in return >100");
                localStorage.clear();
            }
        };
    }, []);
    const handleRegenerateAudit = () => {
        setProgress(0);
        setProgressStatus("Processing...");
        localStorage.removeItem(domainName);
        fetchAuditAutomation();
    };
    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };
    useEffect(() => {
        if (espCount.length > 2) {
            setOverflow("auto");
        } else {
            setOverflow("hidden");
        }
    }, [espCount]);
    console.log(Object.keys(dnsData).length, "dnsCheck");
    return (
        <Box>
            <>
                <Box>
                    <Grid container alignItems="center" justifyContent="space-between">
                        {/* Breadcrumbs on the left */}
                        <Grid item>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator="››"
                                style={{
                                    margin: "10px",
                                    fontSize: "16px",
                                    border: "1px solid #f1f1f1",
                                    padding: "10px",
                                }}
                            >
                                <Link
                                    underline="hover"
                                    color="text.primary"
                                    to="/dashboard/spreadsheet"
                                    sx={{
                                        fontSize: "20px",
                                        color: "primary.main",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                >
                                    BCC Insights
                                </Link>
                                <Typography
                                    color="text.primary"
                                    sx={{
                                        color: "text.primary",
                                        fontSize: "20px",
                                    }}
                                >
                                    Audit Report
                                </Typography>
                            </Breadcrumbs>
                        </Grid>

                        {/* Last Report Date and Button on the right */}
                        <Grid item>
                            <Grid container alignItems="center">
                                <Typography style={{ marginRight: "10px" }}>
                                    Last Report Date : <span>{moment(toDate).format("MMM DD, YYYY")}</span>
                                </Typography>
                                {progress >= 100 && (
                                    <button
                                        variant="contained"
                                        className="custom-button"
                                        onClick={handleRegenerateAudit}
                                    >
                                        Regenerate
                                    </button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {loader || Object.keys(dnsData).length <= 0 ? (
                    <div>
                        <Box style={{ backgroundColor: "#F5F5F5", padding: "20px", borderRadius: "10px" }}>
                            <Box>
                                <Typography>{progressStatus}</Typography>
                                <Typography>Please visit again in 2 to 3 minutes</Typography>
                            </Box>
                            <LinearProgressWithLabel value={progress} />
                        </Box>
                        <Box className="container_box">
                            <Grid container spacing={3}>
                                {/* Score Card Section */}
                                <Grid item xs={12} md={3}>
                                    <Paper
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: 2,
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: "180px",
                                                height: "180px",
                                                position: "relative",
                                            }}
                                        >
                                            <Skeleton variant="circular" width={180} height={180} />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Box style={{ marginTop: "10px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <List>
                                            {[...Array(4)].map((_, index) => (
                                                <ListItem key={index}>
                                                    <Skeleton variant="text" width="100%" height={80} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={2}>
                                            {[...Array(2)].map((_, index) => (
                                                <Grid item xs={12} md={6}>
                                                    <Paper
                                                        style={{
                                                            padding: "20px",
                                                            border: "1px solid #f1f1f1",
                                                            borderRadius: "5px",
                                                            backgroundColor: "#fff",
                                                            height: "300px", // Set max height for scrollable section
                                                            overflowY: "auto", // Enable vertical scrolling
                                                            boxShadow: "none !important",
                                                        }}
                                                    >
                                                        <Skeleton variant="text" width={200} height={40} />

                                                        <List>
                                                            {[...Array(3)].map((_, index) => (
                                                                <ListItem key={index}>
                                                                    <Skeleton variant="text" width="100%" height={80} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Paper>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </div>
                ) : (
                    <Box className="container_box">
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={3} sm={8} md={4}>
                                {loading ? (
                                    <Skeleton variant="circular" width={180} height={180} />
                                ) : (
                                    <Card
                                        style={{
                                            width: "100%",
                                            mb: 2,
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            color: "white",
                                            backgroundColor: "#0884e2",
                                        }}
                                    >
                                        <CardContent style={{ textAlign: "center" }}>
                                            <Typography variant="h4">High Score</Typography>
                                            <Typography variant="h2">{overallScore}</Typography>
                                            <Typography variant="subtitle1">out of 100</Typography>
                                        </CardContent>
                                        <Typography
                                            style={{
                                                margin: "10px",
                                                padding: "10px",
                                                backgroundColor: "#9EC4DF",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            The score is calculated directly from these metrics: Inbox rate, Domain
                                            health, and IP insights
                                        </Typography>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item xs={3} sm={8} md={7}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    {overview.map((data, index) => (
                                        <Grid item md={6} key={index}>
                                            <Card
                                                key={index}
                                                style={{
                                                    "&:hover": { backgroundColor: "#F7F7F9" },
                                                    borderRadius: "8px",
                                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                    mb: 2,
                                                }}
                                            >
                                                <CardActionArea>
                                                    <CardContent>
                                                        <Typography variant="h6" gutterBottom textAlign="center">
                                                            <img
                                                                src={inbox}
                                                                alt=""
                                                                style={{
                                                                    height: "31px",
                                                                    width: "32px",
                                                                }}
                                                            />{" "}
                                                            {data.title}
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            style={
                                                                data.title === "Inbox Count"
                                                                    ? { color: "#536dfe" } // Blue color for Inbox Count
                                                                    : { color: "#e91e63" } // Pink color for other cases
                                                            }
                                                            textAlign="center"
                                                        >
                                                            {data.count}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box
                                    ref={contentRef}
                                    style={{
                                        maxHeight: 160,
                                        overflowY: overflow,
                                        padding: 2,
                                        // Ensure scrollbar is visible when needed
                                        "&::-webkit-scrollbar": {
                                            width: "8px",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "8px",
                                        },
                                        msOverflowStyle: "auto", // IE and Edge
                                        scrollbarWidth: "auto", // Firefox
                                        marginTop:"10px"
                                    }}
                                >
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        {espCount.map((data, index) => (
                                            <Grid item md={espCount[index + 1] || index % 2 !== 0 ? 6 : 12} key={index}>
                                                <Card
                                                    key={index}
                                                    sx={{
                                                        "&:hover": { backgroundColor: "#F7F7F9" },
                                                        borderRadius: "8px",
                                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                        mb: 2,
                                                    }}
                                                >
                                                    <CardActionArea>
                                                        <CardContent>
                                                            <Typography variant="h6" gutterBottom textAlign="center">
                                                                <img
                                                                    src={data.icons}
                                                                    alt=""
                                                                    style={{
                                                                        height: "31px",
                                                                        width: "32px",
                                                                    }}
                                                                />{" "}
                                                                ESP Count
                                                            </Typography>
                                                            <Typography variant="h5" textAlign="center">
                                                                <span style={{ color: "#536dfe" }}>
                                                                    {data.inbox ? data.inbox : 0}
                                                                </span>
                                                                /
                                                                <span style={{ color: "#e91e63" }}>
                                                                    {data.spam ? data.spam : 0}
                                                                </span>
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <div>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#536dfe",
                                                                            width: 10,
                                                                            height: 10,
                                                                        }}
                                                                    />
                                                                    <span>Inbox</span>
                                                                </div>
                                                                <div>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#e91e63",
                                                                            width: 10,
                                                                            height: 10,
                                                                        }}
                                                                    />
                                                                    Spam
                                                                </div>
                                                            </Box>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sm={8} md={4}>
                                {dummyData.map((data, index) => (
                                    <Card
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "120px",
                                            padding: "20px",
                                            marginBottom: "20px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            "&:hover": { backgroundColor: "#F7F7F9" },
                                        }}
                                    >
                                        <Avatar style={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                                        <CardContent style={{ flexGrow: 1, textAlign: "center" }}>
                                            <Typography variant="h5" component="div">
                                                {data.value}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {data.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <ActionComponent onSelect={handlePanelSelect} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {selectedPanel === "Blacklist" && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        maxHeight: "280px",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Domain Blacklist
                                                    </Typography>
                                                    <List>
                                                        {domainBlacklist.length === 0 ? (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary="No data found"
                                                                    sx={{ textAlign: "center" }}
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            domainBlacklist.map((data, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    sx={{
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "5px",
                                                                        marginBottom: "2px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#e0f2f1",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={data.name}
                                                                        sx={{ textAlign: "center" }}
                                                                    />
                                                                    <a
                                                                        className="custom-button"
                                                                        href={`http://${data.url}/`} // External URL
                                                                        target="_blank" // Opens the link in a new tab
                                                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                                                        style={{
                                                                            color: "#fff",
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        Delist
                                                                    </a>
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        maxHeight: "280px",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        IP Blacklist
                                                    </Typography>
                                                    <List>
                                                        {ipBlacklist.length === 0 ? (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary="No data found"
                                                                    sx={{ textAlign: "center" }}
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            ipBlacklist.map((data, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    sx={{
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "5px",
                                                                        marginBottom: "2px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#e0f2f1",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={data.name}
                                                                        sx={{ textAlign: "center" }}
                                                                    />
                                                                    <a
                                                                        className="custom-button"
                                                                        href={`http://${data.url}/`} // External URL
                                                                        target="_blank" // Opens the link in a new tab
                                                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                                                        style={{
                                                                            color: "#fff",
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        Delist
                                                                    </a>
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {selectedPanel === "DNS Reputation" && (
                                        <Box>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#f9f9f9",
                                                            padding: "15px",
                                                            borderRadius: "8px",
                                                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                                            marginBottom: "20px",
                                                        }}
                                                    >
                                                        {Object.keys(dnsData).map((data, key) => {
                                                            return (
                                                                <p
                                                                    key={key}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "8px 0",
                                                                        borderBottom: "1px solid #e0e0e0",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "#333",
                                                                        }}
                                                                    >
                                                                        {data.toUpperCase()}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                dnsData[data] === true
                                                                                    ? "#4CAF50"
                                                                                    : "#F44336", // Green for Pass, Red for Fail
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        {dnsData[data] ? "PASS" : "FAIL"}
                                                                    </span>
                                                                </p>
                                                            );
                                                        })}
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Box>
                                    )}
                                    {selectedPanel === "Overview" && <DNSOverviewChart inboxSpamCount={overview} />}
                                    {selectedPanel === "TemplateList" && (
                                        <Paper
                                            style={{
                                                width: "850px",
                                                padding: "20px",
                                                border: "1px solid #f1f1f1",
                                                borderRadius: "5px",
                                                backgroundColor: "#fff",
                                                boxShadow: "none !important",
                                            }}
                                        >
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {templates.length > 5 && (
                                                    <IconButton onClick={scrollLeft} style={{ marginTop: "-25px" }}>
                                                        <ArrowBackIosIcon style={{ height: "20px" }} />
                                                    </IconButton>
                                                )}
                                                <Box
                                                    ref={scrollRef}
                                                    style={{
                                                        display: "flex",
                                                        overflowX: "hidden",
                                                        pb: 2,
                                                        width: "calc(100% - 64px)",
                                                    }}
                                                >
                                                    {templates.map((template, index) => {
                                                        return (
                                                            <ListItem
                                                                key={template.id}
                                                                className="template-card"
                                                                onClick={() => handleTemplateSelect(template, index)}
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    "&:hover": {
                                                                        backgroundColor: "#e0f2f1",
                                                                    },
                                                                    flex: "0 0 auto",
                                                                    width: "120px",
                                                                    m: 1,
                                                                    p: 2,
                                                                    borderRadius: "5px",
                                                                    cursor: "pointer",
                                                                    border: "1px solid #ccc",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <ListItemText
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {template.subject.slice(0, 20).concat("....")}
                                                                    </Typography>
                                                                </ListItemText>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </Box>
                                                {templates.length > 5 && (
                                                    <IconButton onClick={scrollRight} style={{ marginTop: "-25px" }}>
                                                        <ArrowForwardIosIcon style={{ height: "20px" }} />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <Paper style={{ p: 2, display: "flex" }}>
                                                <Box>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            marginBottom: "20px",
                                                            backgroundColor: "white",
                                                            alignItems: "center",
                                                            height: "40px",
                                                            gap: 2,
                                                            width: "550px", // Set width to 550px
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: "#24a0ed",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            Subject:
                                                        </Typography>
                                                        {selectedTemplate && (
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    padding: "5px 10px",
                                                                    borderRadius: "5px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    flexGrow: 1, // Allows the text to take up remaining space
                                                                }}
                                                            >
                                                                {selectedTemplate.subject}
                                                            </Typography>
                                                        )}
                                                        <ContentCopyIcon
                                                            style={{ marginLeft: "auto", cursor: "pointer" }}
                                                        />
                                                    </Box>

                                                    {/* Body Display Box */}
                                                    <Box
                                                        style={{
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            backgroundColor: "white",
                                                            alignItems: "center",
                                                            overflow: "hidden",
                                                            width: "550px", // Set width to 550px
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: "300px",
                                                                overflowY: "auto",
                                                                padding: "10px",
                                                                backgroundColor: "#f9f9f9",
                                                                borderRadius: "5px",
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: selectedTemplate.originalContent,
                                                            }}
                                                        ></div>
                                                        <ContentCopyIcon
                                                            style={{
                                                                marginTop: "10px",
                                                                cursor: "pointer",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    style={{
                                                        backgroundColor: "#e0f2f1",
                                                        width: "350px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography variant="h6" style={{ mb: 2 }}>
                                                        Spam Analysis
                                                    </Typography>
                                                    <Typography variant="h6" style={{ mb: 2 }}>
                                                        {selectedTemplate.refinedContent}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </Paper>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </>
            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} style={{ width: "100%" }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default AuditReport;
