import React, { useState, useEffect } from "react";
import { Button, Box, Snackbar, Alert } from "@mui/material";
import DomainListDialog from "./DomainListDialog";
import SenderListDialog from "./SenderListDialog";
import ClientContentDialog from "./ClientContentDialog";
import TableComponent from "./TableComponent";
import SearchField from "./SearchComponent";
import withSenderDialog from "./withSenderDialog";
import "./ManualAudit.css";
import moment from "moment";
import Pagination from "@mui/material/Pagination";

const headers = ["Checkbox","Domain", "Sender", "Report-Date", "Audit Report", "Report Status",'Actions'];

const ManualAudit = () => {
    const [domainList, setDomainList] = useState([]);
    const [senderList, setSenderList] = useState([
        "mukesh.jha@xemailverify.com",
        "abc@xemailverify.com",
        "xyz@xemailverify.com",
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [openDomainboxDialog, setOpenDomainboxDialog] = useState(false);
    const [senderListDialog, setSenderListDialog] = useState(false);
    const [selectedSender, setSelectedSender] = useState(null);
    const [isClientContentInputOpen, setIsClientContentInputOpen] = useState(false);
    const [clientContent, setClientContent] = useState({ subject: "", body: "" });
    const [loaderdomainList, setLoaderDomainList] = useState(false);
    const [auditRecords, setAuditRecords] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [tableloading, setTableLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 20;

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    // Slice the data for the current page
    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = auditRecords.filter((item) => {
            return item.domain.toLowerCase().includes(value);
        });
        setFilteredData(filtered);
    };

    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    const handleAddEventClick = () => {
        window.location.href = `${process.env.REACT_APP_URL}/dashboard/integration`;
    };

    const handleBack = () => {
        setOpenDomainboxDialog(true);
        setSenderListDialog(false);
    };

    const handlesenderClose = () => {
        setSenderListDialog(false);
    };

    const handledomainClose = () => {
        setOpenDomainboxDialog(false);
    };

    const hanldeOnDeleteClick = (id) => {
        let allData = auditRecords;
        allData = allData.filter((data, index) => {
            return index !== id;
        });
        setFilteredData(allData);
        setAuditRecords(allData);
    };

    const handleShowDomainListEventClick = () => {
        setLoaderDomainList(true);
        fetch(`${REACT_APP_SERVER_URL}/fetchDomain`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    // Set the domain too...
                    // setDomainList()
                    setDomainList(data.data);
                    setOpenDomainboxDialog(true);
                } else {
                    setErrorMessage("Domain not found");
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((error) => {
                console.error("Error fetching domain list:", error);
            })
            .finally(() => {
                setLoaderDomainList(false);
            });
    };

    const handleCloseDomainDialog = () => {
        setOpenDomainboxDialog(false);
    };

    const handleDomainClick = (domain) => {
        fetch(`${REACT_APP_SERVER_URL}/fetchSendersFromDomain?domain=${domain}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    // Set the sender too...
                    setSenderList(data.data);
                    setOpenDomainboxDialog(false);
                    setSenderListDialog(true);
                }
            });
    };

    const handleSenderClick = (sender) => {
        setSelectedSender(sender);
    };

    const handleSendMailClick = async () => {
        if (selectedSender) {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/manualAuditSafeContentCheck?sender=${selectedSender}`,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        method: "GET",
                        credentials: "include",
                    }
                );

                const data = await response.json();

                if (response.ok && data.success) {
                    setSenderListDialog(false);
                    setIsClientContentInputOpen(true);
                } else {
                    console.error("Failed to send mail.", data);
                }
            } catch (error) {
                console.error("An error occurred while sending mail:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.error("No sender selected. Please select a sender.");
        }
    };

    const handleClientContentChange = (field) => (event) => {
        setClientContent({ ...clientContent, [field]: event.target.value });
    };

    const handleClientContentSubmit = () => {
        setIsClientContentInputOpen(false);
    };

    const handleBulkDelete = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/DeleteManualAuditRecords`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({ id: selectedRows }),
            });

            const data = await response.json();

            if (data.success) {
                setFilteredData([]);
                setSuccessMessage("Delete Successfully");
                setSuccessSnackbarOpen(true);
                fetchAuditRecords();
            } else {
                setErrorMessage("Failed to delete rows");
                setErrorSnackbarOpen(true);
                throw new Error("Failed to delete rows.");
            }
        } catch (error) {
            console.error("Error while deleting the rows:", error);
            setErrorMessage("An error occurred while deleting rows.");
            setErrorSnackbarOpen(true);
        }
    };

    const closeClientContentInput = () => {
        setIsClientContentInputOpen(false);
    };

    useEffect(() => {
        fetchAuditRecords();
    }, []);
    const fetchAuditRecords = async () => {
        setTableLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/fetchManualAuditRecords`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "GET",
                credentials: "include",
            });

            const data = await response.json();

            if (response.ok && data.success) {
                setTableLoading(false);
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].updatedAt) {
                        data.data[i].updatedAt = moment(data.data[i].updatedAt).format("lll");
                        data.data[i].processStatus = !["error", "completed"].includes(data.data[i].processStatus)
                            ? "PROGRESS"
                            : data.data[i].processStatus.toLowerCase() === "error"
                              ? "ERROR"
                              : data.data[i].processStatus.toLowerCase() === "completed"
                                ? "SUCCESS"
                                : null;
                    }
                }
                data.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setAuditRecords(data.data);
                const result = data.data.map((item) => {
                    const { uniqueProcessCode, ...rest } = item;
                    // delete item.uniqueProcessCode;
                    return rest;
                });
                setFilteredData(result);
            } else {
                console.error("Failed to fetch records.", data.message);
                setErrorMessage("No table data found");
                setErrorSnackbarOpen(true);
            }
        } catch (error) {
            console.error("An error occurred while fetching records:", error);
        }
    };

    const EnhancedTable = withSenderDialog(TableComponent);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/DeleteManualAuditRecords`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({ id }),
            });
            const data = await response.json();

            if (data.success) {
                fetchAuditRecords();
                setSuccessMessage(" Delete Successfully");
                setSuccessSnackbarOpen(true);
            } else {
                setErrorMessage("Failed to delete row");
                setErrorSnackbarOpen(true);
                throw new Error("Failed to delete row.");
            }
        } catch (error) {
            console.error("Error while deleting the row:", error);
        }
    };

    return (
        <>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box>
                        <SearchField
                            label="Filter by Domain"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleShowDomainListEventClick}
                            sx={{ marginRight: 2 }}
                        >
                            Select Domain to Proceed
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleAddEventClick}>
                            Add +
                        </Button>

                        <Button
                            variant="outlined"
                            color="error"
                            disabled={selectedRows.length <= 1}
                            onClick={handleBulkDelete}
                            sx={{ marginLeft: 2 }}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>

                <Box className="table-box">
                    <EnhancedTable
                        auditRecords={auditRecords}
                        data={paginatedData}
                        headers={headers}
                        handleErrorStatus={(msg) => {
                            setErrorMessage(msg);
                            setErrorSnackbarOpen(true);
                        }}
                        senderColumnName="senderName"
                        handleOnDeleteClick={hanldeOnDeleteClick}
                        onDeleteRows={setSelectedRows}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        setAuditRecords={setAuditRecords}
                        deleteFunction={handleDelete}
                        tableloading={tableloading}
                    />
                    <Pagination
                        count={Math.ceil(filteredData.length / rowsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                    />
                </Box>

                <DomainListDialog
                    open={openDomainboxDialog}
                    onClose={handleCloseDomainDialog}
                    domainList={domainList}
                    onDomainClick={handleDomainClick}
                    loading={loaderdomainList}
                    Close={handledomainClose}
                />

                <SenderListDialog
                    open={senderListDialog}
                    close={handlesenderClose}
                    senderList={senderList}
                    selectedSender={selectedSender}
                    onSenderSelect={handleSenderClick}
                    back={handleBack}
                    domainList={domainList}
                />

                <ClientContentDialog
                    open={isClientContentInputOpen}
                    content={clientContent}
                    onChange={handleClientContentChange}
                    onClose={closeClientContentInput}
                    onSubmit={handleClientContentSubmit}
                />
            </Box>

            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ManualAudit;
