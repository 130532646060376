import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./Header.css";
import { ReactComponent as Back } from "./back-arrow.svg";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import Progressbar from "../Progress_bar/Progress_bar";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import {
    Box,
    Grid,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
    Paper,
    CardContent,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#004aad",
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Header = () => {
    const { domain, clientName } = useParams();
    const [selectedFeature, setSelectedFeature] = useState("Spam Rate");
    const [selectedDateRange, setSelectedDateRange] = useState(7);
    const [showHelp, setShowHelp] = useState(false);
    const [spamRate, setSpamRate] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [IpReputation, setIpReputation] = useState([]);
    const [DomainReputation, setDomainReputation] = useState([]);
    const [Authentication, setAuthentication] = useState([]);
    const [Encryption, setEncryption] = useState([]);
    const [splineChartData, setSplineChartData] = useState([]);
    const [domainChart, setdomainChart] = useState([]);
    const [authdata, setAuthData] = useState([]);
    const [encryptionChart, setEncryptionChart] = useState([]);
    const [IpChart, setIpChart] = useState([]);
    const [supportform, setSupportform] = useState(false);
    const [mail, setmail] = useState("");
    const [Queries, setQueries] = useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [Reports, setReports] = useState(true);
    const [boxDisabled, setBoxDisabled] = useState(false);
    const [Clientlist, setClientList] = useState([]);
    // const [selectedDomain, setSelectedDomain] = useState('');
    // const [domain, setDomain] = useState('');

    useEffect(() => {
        handleDomainList(Clientlist);
        if (selectedFeature === "Spam Rate") {
            fetchSpamRate();
        } else if (selectedFeature === "IP Reputation") {
            fetchIpReputation();
        } else if (selectedFeature === "Domain Reputation") {
            fetchDomainReputation();
        } else if (selectedFeature === "Authentication") {
            fetchAuthentication();
        } else if (selectedFeature === "Encryption") {
            fetchEncryption();
        }
    }, [selectedDateRange, selectedFeature]);

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const formatDate = (inputDate) => {
        const year = inputDate.slice(0, 4);
        const month = inputDate.slice(4, 6);
        const day = inputDate.slice(6, 8);
        let newDate = `${day}-${month}-${year}`;

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

        const parts = newDate.split("-");
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const monthIndex = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);

            if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
                if (monthIndex >= 0 && monthIndex < months.length) {
                    const monthName = months[monthIndex];
                    return `${day} ${monthName} ${year}`;
                }
            }
        }
        return "Invalid Date";
    };

    function toggleform() {
        setSpamRate([]);
        setAuthentication([]);
        setEncryption([]);
        setDomainReputation([]);
        setIpReputation([]);
        setReports(false);
        setSupportform(true);
        setBoxDisabled(true);
    }

    function SubmitQueryIssue(e) {
        e.preventDefault();
        if (!mail || !Queries) {
            alert("Email and Queries are required!");
            return;
        }
        setLoading(true);

        fetch(`${REACT_APP_SERVER_URL}/QueryMail`, {
            headers: {
                Accept: "application/json",
                "Contendt-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                mail,
                Queries,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setLoading(false);
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Query Request Send Successfully, The team will contact you soon");
                    fetchSpamRate();
                }
            });
    }

    const fetchSpamRate = async () => {
        setReports(true);
        setLoading(true);
        setSupportform(false);
        try {
            fetch(`${REACT_APP_SERVER_URL}/spam-rate?domain=${domain}&period=${selectedDateRange}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setLoading(false);
                    console.log(data, "data is getting");
                    let array = [];
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i] == null) {
                            continue;
                        } else {
                            if (data.data[i].trafficStats == null) {
                                data.data[i].trafficStats = 0;
                            }

                            array.push(data.data[i]);
                        }
                    }

                    const Data = {
                        labels: array.map((data) => formatDate(data.date)),
                        datasets: [
                            {
                                label: "Spam Rate",
                                data: array.map((data) => data.trafficStats),
                                fill: false,
                                spanGaps: true,
                                borderColor: "rgb(94, 151, 246) ",
                                backgroundColor: "rgb(94, 151, 246)",
                            },
                        ],
                    };
                    setSplineChartData(Data);
                    setSpamRate(array);
                });
        } catch (error) {
            console.error("Error fetching spam rate:", error);
        }
    };

    // Y Axis for the Spam Rate Calculator....
    const chartOptions = {
        scales: {
            y: {
                min: 0,
                max: getMaxValue(), // Call a function to get the dynamic max value
                ticks: {
                    callback: function (value, index, values) {
                        // Calculate the step size dynamically based on the maximum value
                        const stepSize = Math.floor(getMaxValue() / 10);
                        return `${index * stepSize}%`;
                    },
                },
            },
        },
    };
    // Function to determine the dynamic max value
    function getMaxValue() {
        return 50; // Set your dynamic max value here
    }

    // Y Axis for the domain Reputation...
    const domainchartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                max: 2,
                ticks: {
                    callback: function (value, index, values) {
                        switch (value) {
                            case 0:
                                return "BAD";
                            case 1:
                                return "Low";
                            case 1.6:
                                return "Medium";
                            case 2:
                                return "HIGH";
                        }
                    },
                },
            },
        },
    };
    // Y Axis for the Ip Reputation...

    const IpchartOptions = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 20,
                    callback: function (value, index, values) {
                        return `${value}%`;
                    },
                },
            },
        },
    };

    // Y Axis for the Authentication ...
    const AuthchartOptions = {
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    callback: function (value, index, values) {
                        switch (value) {
                            case 0:
                                return `${0}%`;
                            case 20:
                                return `${20}%`;
                            case 40:
                                return `${40}%`;
                            case 60:
                                return `${60}%`;
                            case 80:
                                return `${80}%`;
                            case 100:
                                return `${100}%`;
                        }
                    },
                },
            },
        },
    };

    // Y Axis for the Encryption...
    const EncryptionOptions = {
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    callback: function (value, index, values) {
                        switch (value) {
                            case 0:
                                return `${0}%`;
                            case 20:
                                return `${20}%`;
                            case 40:
                                return `${40}%`;
                            case 60:
                                return `${60}%`;
                            case 80:
                                return `${80}%`;
                            case 100:
                                return `${100}%`;
                        }
                    },
                },
            },
        },
    };

    const fetchIpReputation = async () => {
        setLoading(true);
        try {
            fetch(`${REACT_APP_SERVER_URL}/IpReputation?domain=${domain}&period=${selectedDateRange}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // console.log(data, 'ipReputation Data is coming to fetch')
                    setLoading(false);
                    let array = [];
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i] == null) {
                            continue;
                        } else {
                            array.push(data.data[i]);
                        }
                    }
                    let ipRep = [];
                    let ipPercentage = [];

                    for (let i = 0; i < array.length; i++) {
                        let count = 0;
                        let bad = 0;
                        let low = 0;
                        let high = 0;
                        let medium = 0;

                        for (let j = 0; j < array[i].IpReputation.length; j++) {
                            if (array[i].IpReputation[j].ipCount !== undefined) {
                                let reputationStatus = array[i].IpReputation[j].reputation;
                                // console.log(array[i].IpReputation[j].reputation,'rep')

                                let realcount = parseInt(array[i].IpReputation[j].ipCount);
                                if (reputationStatus === "BAD") {
                                    bad = bad + realcount;
                                } else if (reputationStatus === "LOW") {
                                    low = low + realcount;
                                } else if (reputationStatus === "MEDIUM") {
                                    medium = medium + realcount;
                                } else {
                                    high = high + realcount;
                                }
                                count = count + realcount;

                                // array[i].IpReputation[j].sampleIps.push();
                                // console.log(array[i].IpReputation[j].sampleIps)

                                // ipCount = ipCount + realCount;
                                ipRep.push({
                                    date: array[i].date,
                                    rep: array[i].IpReputation[j].reputation,
                                    ip: array[i].IpReputation[j].sampleIps,
                                });
                            }
                        }
                        let Highpercentage = ((high / count) * 100).toFixed(2);
                        let Lowpercentage = ((low / count) * 100).toFixed(2);
                        let Mediumpercentage = ((medium / count) * 100).toFixed(2);
                        let badpercentage = ((bad / count) * 100).toFixed(2);

                        ipPercentage.push({
                            date: array[i].date,
                            High: Highpercentage,
                            Low: Lowpercentage,
                            Medium: Mediumpercentage,
                            Bad: badpercentage,
                        });
                    }

                    const Data = {
                        labels: ipPercentage.map((data) => formatDate(data.date)),
                        datasets: [
                            {
                                label: "BAD",
                                data: ipPercentage.map((data) => data.Bad),
                                borderColor: "red",
                                fill: false,
                                spanGaps: true,
                                backgroundColor: "red",
                            },
                            {
                                label: "LOW",
                                data: ipPercentage.map((data) => data.Low),
                                borderColor: "orange",
                                fill: false,
                                spanGaps: true,
                                backgroundColor: "orange",
                            },
                            {
                                label: "MEDIUM",
                                data: ipPercentage.map((data) => data.Medium),
                                borderColor: "yellow",
                                spanGaps: true,
                                backgroundColor: "yellow",
                            },
                            {
                                label: "HIGH",
                                data: ipPercentage.map((data) => data.High),
                                borderColor: "rgb(13, 168, 93)",
                                fill: false,
                                spanGaps: true,

                                backgroundColor: "green",
                            },
                        ],
                    };

                    setIpChart(Data);
                    setIpReputation(ipRep);
                });
        } catch (error) {
            console.error("Error fetching spam rate:", error);
        }
    };

    const fetchDomainReputation = async () => {
        setLoading(true);
        try {
            fetch(`${REACT_APP_SERVER_URL}/DomainReputation?domain=${domain}&period=${selectedDateRange}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setLoading(false);
                    let array = [];
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i] == null) {
                            continue;
                        } else {
                            array.push(data.data[i]);
                        }
                    }

                    const Data = {
                        labels: array.map((data) => formatDate(data.date)),
                        datasets: [
                            {
                                label: "Domain Reputation",
                                data: array.map((data) => {
                                    switch (data.DomainReputation) {
                                        case "BAD":
                                            return 0;
                                        case "LOW":
                                            return 1;
                                        case "MEDIUM":
                                            return 1.6;
                                        case "HIGH":
                                            return 2;
                                    }
                                }),

                                fill: false,
                                spanGaps: true,
                                borderColor: "rgb(94,151,246)",
                                backgroundColor: "rgb(94,151, 246)",
                            },
                        ],
                    };
                    setdomainChart(Data);
                    setDomainReputation(array);
                });
        } catch (error) {
            console.error("Error fetching spam rate:", error);
        }
    };

    const fetchAuthentication = async () => {
        setLoading(true);
        try {
            fetch(`${REACT_APP_SERVER_URL}/Authentication?domain=${domain}&period=${selectedDateRange}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setLoading(false);
                    let array = [];
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i] == null) {
                            continue;
                        } else {
                            array.push(data.data[i]);
                        }
                    }
                    const Data = {
                        labels: array.map((data) => formatDate(data.date)),
                        datasets: [
                            {
                                label: "DKIM success rate",
                                data: array.map((data) => data.Authentication.spf),
                                borderColor: "blue",
                                fill: false,
                                spanGaps: true,
                                borderColor: "rgb(94, 151, 246) ",
                                backgroundColor: "rgb(94, 151, 246)",
                            },
                            {
                                label: "SPF success rate",
                                data: array.map((data) => data.Authentication.spf),

                                fill: false,
                                spanGaps: true,
                                borderColor: "yellow",
                                backgroundColor: "yellow",
                            },
                            {
                                label: "DMARC success rate",
                                data: array.map((data) => data.Authentication.spf),

                                fill: false,
                                spanGaps: true,
                                borderColor: "orange ",
                                backgroundColor: "orange",
                            },
                        ],
                    };
                    setAuthData(Data);
                    setAuthentication(array);
                });
        } catch (error) {
            console.error("Error fetching spam rate:", error);
        }
    };
    const fetchEncryption = async () => {
        setLoading(true);
        try {
            fetch(`${REACT_APP_SERVER_URL}/Encryption?domain=${domain}&period=${selectedDateRange}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setLoading(false);
                    let array = [];
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i] == null) {
                            continue;
                        } else {
                            array.push(data.data[i]);
                        }
                    }
                    const Data = {
                        labels: array.map((data) => formatDate(data.date)),
                        datasets: [
                            {
                                label: "Encryption",

                                data: array.map((data) => data.encryption),

                                fill: false,
                                spanGaps: true,
                                borderColor: "rgb(94,151,246)",
                                backgroundColor: "rgb(94,151, 246)",
                            },
                        ],
                    };
                    setEncryptionChart(Data);
                    setEncryption(array);
                });
        } catch (error) {
            console.error("Error fetching spam rate:", error);
        }
    };

    function refineData(data) {
        console.log(data, "data");

        if (data === null) {
            return 0;
        } else return data;
    }

    async function handleDomainList(client) {
        fetch(`${REACT_APP_SERVER_URL}/findingDomainListWithClientName?ClientName=${clientName}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                console.log(data, "getting data");
                if (data.status === 200) {
                    console.log(data, "is a rha hai ");
                    setClientList(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    // const handleDomainChange = (event) => {
    //     const Alldomain = event.target.value;
    //     setSelectedDomain(Alldomain);
    //     setDomain(Alldomain);
    // };

    return (
        <div>
            <header className="header">
                <Link to={`/dashboard/DomainReputation/domain/${clientName}`}>
                    <Back style={{ color: "white" }}></Back>
                </Link>
                {/* <Box sx={{ display: "flex", alignItems: "center", marginRight: '20px' }}>
                    <TextField
                        select
                        label="Enter domain"
                        value={selectedDomain}
                        onChange={handleDomainChange}
                        placeholder="Select a domain"
                        sx={{
                            width: "250px",
                            marginRight: '20px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        {Clientlist.map((client) => (
                            <MenuItem key={client.id} value={client.domain}>
                                {client.domain}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box> */}
                {domain && <span style={{ marginLeft: "20px" }}>{domain}</span>}

                <div className="feature-options">
                    <select
                        className="feature-select"
                        value={selectedFeature}
                        onChange={(e) => setSelectedFeature(e.target.value)}
                    >
                        <option value="Spam Rate">Spam Rate</option>
                        <option value="IP Reputation">IP Reputation</option>
                        <option value="Domain Reputation">Domain Reputation</option>
                        <option value="Authentication">Authentication</option>
                        <option value="Encryption">Encryption</option>
                    </select>
                </div>
                <div className="date-selector">
                    <select
                        className="date-select"
                        value={selectedDateRange}
                        onChange={(e) => setSelectedDateRange(e.target.value)}
                    >
                        <option value="7">Last 7 Days</option>
                        <option value="30">Last 30 Days</option>
                        <option value="60">Last 60 Days</option>
                        <option value="90">Last 90 Days</option>
                        <option value="120">Last 120 Days</option>
                    </select>
                </div>
                <div className="help" style={{ marginRight: "20px" }}>
                    <button className="help-button" onClick={() => setShowHelp(!showHelp)}>
                        <span>Help</span>
                        {showHelp && (
                            <ul className="help-menu">
                                <li>FAQ</li>
                                <li onClick={toggleform}>Contact Support</li>
                            </ul>
                        )}
                    </button>
                </div>
            </header>
            {supportform && (
                <Box mt={3} justifyContent="center">
                    <Paper id="supportForm" elevation={3} style={{ padding: "20px" }}>
                        <form>
                            <label id="emailInput">Email : </label>

                            <input
                                id="inputField"
                                placeholder="Enter your Email Address"
                                value={mail}
                                onChange={(e) => {
                                    setmail(e.target.value);
                                }}
                                required
                            ></input>
                            <br></br>
                            <br></br>

                            <label id="emailInput">Queries : </label>

                            <textarea
                                id="inputField"
                                required
                                placeholder="Enter your Queries"
                                value={Queries}
                                onChange={(e) => {
                                    setQueries(e.target.value);
                                }}
                            ></textarea>
                            <br></br>

                            <div id="buttons">
                                {/* <button id='closeformSubmit' onClick={fetchSpamRate}>Close</button> */}
                                <Button variant="outlined" color="error" id="closeformSubmit" onClick={fetchSpamRate}>
                                    Close
                                </Button>

                                <Button
                                    id="supportformSubmit"
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    onClick={SubmitQueryIssue}
                                >
                                    Success
                                </Button>
                            </div>
                        </form>
                    </Paper>
                </Box>
            )}

            {Loading ? (
                <Box sx={{ width: "100%", display: "center", justifyContent: "center", marginTop: 3 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ maxWidth: "1600px", minWidth: "1200px", margin: "auto" }}>
                    <>
                        {selectedFeature === "Spam Rate" && spamRate.length !== 0 && (
                            <Box sx={{ marginTop: "15px" }}>
                                <Line data={splineChartData} width={1200} height={264} options={chartOptions}></Line>
                                <Box sx={{ marginTop: "20px" }}>
                                    <Box sx={{ marginLeft: "30px" }}>
                                        <span style={{ fontSize: "22px", fontWeight: "700px" }}>Domain Reputation</span>
                                        <br />
                                    </Box>
                                    <Box sx={{ padding: "20px", marginTop: "20px" }}>
                                        <TableContainer component={Paper} elevation={3}>
                                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Date</StyledTableCell>
                                                        <StyledTableCell>Spam Record</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {spamRate.map((data, i) => (
                                                        <StyledTableRow key={i}>
                                                            <StyledTableCell component="th">
                                                                {formatDate(data.date)}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Typography
                                                                    sx={{
                                                                        display: "flex",
                                                                        textAlign: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "start",
                                                                    }}
                                                                >
                                                                    <Progressbar
                                                                        bgcolor="#004aad"
                                                                        height={1}
                                                                        progress={refineData(data.trafficStats)}
                                                                    />
                                                                    {data.trafficStats !== null
                                                                        ? `${data.trafficStats}%`
                                                                        : `0%`}
                                                                </Typography>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}{" "}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                       
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {selectedFeature === "Spam Rate" && spamRate.length === 0 && !boxDisabled && (
                            <Box sx={{ marginTop: "15px" }}>
                                <span id="noData">
                                    No data to display at present. Please come back later.
                                    <br />
                                </span>
                                <span id="msg">
                                    DomainReputation Tools requires your domain to satisfy certain conditions before
                                    data is visible for this chart. Refer to the Help page for more details.
                                </span>
                            </Box>
                        )}
                    </>
                    <>
                        {selectedFeature === "IP Reputation" && IpReputation.length !== 0 && (
                            <Box sx={{ marginTop: "15px" }}>
                                <Bar data={IpChart} width={1200} height={264} options={IpchartOptions}></Bar>

                                <Box sx={{ padding: "20px", marginTop: "20px" }}>
                                    <TableContainer component={Paper} elevation={3}>
                                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Date</StyledTableCell>
                                                    <StyledTableCell>Domain Reputation</StyledTableCell>
                                                    <StyledTableCell>Ips</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {IpReputation.map((data, i) => (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell component="th">
                                                            {formatDate(data.date)}
                                                        </StyledTableCell>
                                                        <StyledTableCell>{data.rep}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {" "}
                                                            {data.ip.map((ip, index) => (
                                                                <li key={index} style={{ fontSize: "12px" }}>
                                                                    {ip}
                                                                </li>
                                                            ))}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}{" "}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    
                                </Box>
                            </Box>
                        )}

                        {selectedFeature === "IP Reputation" && IpReputation.length === 0 && !boxDisabled && (
                            <Box sx={{ marginTop: "15px" }}>
                                <span id="noData">
                                    No data to display at present. Please come back later.
                                    <br />
                                </span>
                                <span id="msg">
                                    DomainReputation Tools requires your domain to satisfy certain conditions before
                                    data is visible for this chart. Refer to the Help page for more details.
                                </span>
                            </Box>
                        )}
                    </>

                    <>
                        {selectedFeature === "Domain Reputation" && DomainReputation.length !== 0 && (
                            <Box sx={{ padding: "20px", marginTop: "15px" }}>
                                <Line data={domainChart} width={1200} height={264} options={domainchartOptions}></Line>
                                {DomainReputation.length !== 0 && (
                                    <Box sx={{ marginLeft: "30px", marginTop: "20px" }}>
                                        <TableContainer component={Paper} elevation={3}>
                                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Date</StyledTableCell>
                                                        <StyledTableCell>Domain Reputation</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {DomainReputation &&
                                                        DomainReputation.map((data, i) => (
                                                            <StyledTableRow key={i}>
                                                                <StyledTableCell component="th">
                                                                    {formatDate(data.date)}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {data.DomainReputation}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}{" "}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Box>
                                )}
                            </Box>
                        )}
                        {selectedFeature === "Domain Reputation" && DomainReputation.length === 0 && !boxDisabled && (
                            <Box sx={{ marginTop: "15px" }}>
                                <span id="noData">
                                    No data to display at present. Please come back later.
                                    <br />
                                </span>
                                <span id="msg">
                                    DomainReputation Tools requires your domain to satisfy certain conditions before
                                    data is visible for this chart. Refer to the Help page for more details.
                                </span>
                            </Box>
                        )}
                    </>
                    <>
                        {selectedFeature === "Authentication" && Authentication.length !== 0 && (
                            <Box sx={{ marginTop: "15px" }}>
                                <Line data={authdata} width={1200} height={264} options={AuthchartOptions}></Line>
                                {Authentication.length !== 0 && (
                                    <Box sx={{padding:'20px', marginTop: "20px" }}>
                                        <Box>
                                            <Typography>Authentication</Typography>
                                        </Box>

                                        <TableContainer component={Paper} elevation={3}>
                                            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left">Date</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            DKIM success rate
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">SPF success rate</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            DMARC success rate
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Authentication &&
                                                        Authentication.map((data, i) => (
                                                            <StyledTableRow>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {formatDate(data.date)}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >{`${data.Authentication.dkim}%`}</StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {`${data.Authentication.spf}%`}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {`${data.Authentication.dmarc}%`}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Box>
                                )}
                            </Box>
                        )}
                        {selectedFeature === "Authentication" && Authentication.length === 0 && !boxDisabled && (
                            <Box sx={{ marginTop: "15px" }}>
                                <span id="noData">
                                    No data to display at present. Please come back later.
                                    <br />
                                </span>
                                <span id="msg">
                                    DomainReputation Tools requires your domain to satisfy certain conditions before
                                    data is visible for this chart. Refer to the Help page for more details.
                                </span>
                            </Box>
                        )}
                    </>
                    <>
                        {selectedFeature === "Encryption" && Encryption.length !== 0 && (
                            <Box sx={{ marginTop: "15px" }}>
                                <Line
                                    data={encryptionChart}
                                    options={EncryptionOptions}
                                    width={1200}
                                    height={264}
                                ></Line>
                                {Encryption.length !== 0 && (
                                    <Box sx={{ padding: "20px", marginTop: "20px" }}>
                                        <Box>
                                            <Typography>Encryption</Typography>
                                            <Typography></Typography>
                                        </Box>
                                      
                                        <TableContainer component={Paper} elevation={3}>
                                            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left">Date</StyledTableCell>
                                                        <StyledTableCell align="left">Inbound TLS rate</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            Outbound TLS rate
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Encryption &&
                                                        Encryption.map((data, i) => (
                                                            <StyledTableRow>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {formatDate(data.date)}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >{`${data.encryption}%`}</StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {"N/A"}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                            </Box>
                        )}
                        {selectedFeature === "Encryption" && Encryption.length === 0 && !boxDisabled && (
                            <Box sx={{ marginTop: "15px" }}>
                                <span id="noData">
                                    No data to display at present. Please come back later.
                                    <br />
                                </span>
                                <span id="msg">
                                    DomainReputation Tools requires your domain to satisfy certain conditions before
                                    data is visible for this chart. Refer to the Help page for more details.
                                </span>
                            </Box>
                        )}
                    </>
                </Box>
            )}

            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Header;
