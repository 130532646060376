import React, { useState } from "react";
import "./Register.css";
import cookie from "cookiejs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    TextField,
    CircularProgress,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SnackBar from "./common/SnackBar";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import xaudit1 from "../img/icons/xaudit1.png";
import Avatar from "@mui/material/Avatar";
import { Check, Close } from "@mui/icons-material";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const Register = () => {
    const navigate = useNavigate();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [termsCondition, setTermsCondition] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (isLogin) {
            if (email === "rakesh@saleshive.com") {
                console.log("lies in rakesh");
                navigate("/dashboard/dataDashboard");
            }
            console.log("outside rakesh");
            navigate("/dashboard/InboxPlacement");
        }
    }, [navigate]);
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(value));
    };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        setIsLengthValid(value.length > 5);

        setHasUppercase(/[A-Z]/.test(value));

        setHasLowercase(/[a-z]/.test(value));
    };
    const isAllPasswordConditionsMet = () => {
        return isLengthValid && hasUppercase && hasLowercase;
    };
    const passwordRequirements = [
        {
            text: "Password length must be greater than 5",
            check: (password) => password.length > 5,
        },
        {
            text: "Must have one uppercase character",
            check: (password) => /[A-Z]/.test(password),
        },
        {
            text: "Must have one lowercase character",
            check: (password) => /[a-z]/.test(password),
        },
    ];
    const isAllConditionMet =
        buttonLoading ||
        !termsCondition ||
        !isAllPasswordConditionsMet() ||
        !firstname ||
        firstname.length < 3 ||
        !lastname ||
        lastname.length < 3 ||
        !isValidEmail ||
        confirmPassword !== password;

    const registerButtonHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);

        fetch(`${REACT_APP_SERVER_URL}/register`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                fname: firstname,
                lname: lastname,
                password: password,
                email: email,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log("register successfully");
                    setButtonLoading(false);
                    setSuccessSnackbarOpen(true);
                    setTimeout(() => {
                        navigate("/login");
                    }, 5000);
                    return response.json();
                } else {
                    console.log("register failed");
                    setButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);
                setErrorMessage(data.msg);
                setSuccessMessage(data.msg);
            });
    };
    console.log(termsCondition);
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box className="full">
                <Box
                    sx={{
                        width: "60%",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        backgroundColor: "whitesmoke",
                    }}
                >
                    <Box sx={{ display: "flex", textAlign: "center", alignItems: "center", marginTop: "10px" }}>
                        <Link exact to="/">
                            <img src={xaudit1} alt="/" style={{ width: "200px", height: "56px", marginTop: "-10px" }} />
                        </Link>
                    </Box>
                    <Box sx={{ margin: "200px", marginTop: "150px" }}>
                        <Typography
                            className="second-child-typography"
                            sx={{ textAlign: "center", fontSize: "30px", fontWeight: "500px", lineHeight: "38px" }}
                        >
                            Deliverability has saved us thousands of hours of work. We’re able to spin up projects and
                            features much faster.
                        </Typography>
                        <Avatar
                            sx={{
                                width: "70px",
                                height: "70px",
                                textAlign: "center",
                                margin: "15px",
                                marginLeft: "43%",
                            }}
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                        />
                        <Typography
                            className="second-child-typography"
                            sx={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "600px",
                                lineHeight: "28px",
                                marginRight: "25px",
                            }}
                        >
                            Pippa Wilkinson
                        </Typography>
                        <Typography
                            className="second-child-typography"
                            sx={{
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: "500px",
                                color: "GrayText",
                                marginRight: "30px",
                                lineHeight: "28px",
                            }}
                        >
                            Head Of Desgin, Layers
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500px",
                                lineHeight: "20px",
                                color: "GrayText",
                                marginRight: "80%",
                                marginTop: "-10px",
                                padding: "10px",
                            }}
                        >
                            XGrowth LLC
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "40%", backgroundColor: "white" }}>
                    <Box sx={{ padding: "40px", marginTop: "15px" }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: 600, lineHeight: "38px", pb: 3 }}>
                            Create a New Account
                        </Typography>
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Typography sx={{ fontSize: "14px", pb: 1 }}>*First Name</Typography>
                                <TextField
                                    placeholder="First"
                                    onChange={(e) => setFirstname(e.target.value)}
                                    fullWidth
                                />
                                {firstname && firstname.trim().length < 3 && (
                                    <Typography sx={{ fontSize: "14px", pb: 1, color: "red" }}>
                                        First name must be at least 3 characters long.
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Typography sx={{ fontSize: "14px", pb: 1 }}>*Last Name</Typography>
                                <TextField placeholder="Last" onChange={(e) => setLastname(e.target.value)} fullWidth />
                                {lastname && lastname.trim().length < 3 && (
                                    <Typography sx={{ fontSize: "14px", pb: 1, color: "red" }}>
                                        Last name must be at least 3 characters long.
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>*Email</Typography>
                        <div>
                            <TextField
                                placeholder="Email"
                                type="email"
                                onChange={handleEmailChange}
                                value={email}
                                fullWidth
                                sx={{ pb: 1 }}
                            />
                            {!isValidEmail && email && (
                                <Typography sx={{ fontSize: "14px", pb: 1, color: "red" }}>
                                    Enter a valid email address (e.g., user@domain.com).
                                </Typography>
                            )}
                        </div>
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>*Password</Typography>
                        <TextField
                            sx={{ pb: 2 }}
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                        {password && !isAllPasswordConditionsMet() && (
                            <ul style={{ listStyleType: "none", paddingLeft: 0, fontSize: "14px" }}>
                                {passwordRequirements.map((requirement, index) => {
                                    const isValid = requirement.check(password);
                                    return (
                                        <li
                                            key={index}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: isValid ? "green" : "red",
                                            }}
                                        >
                                            {isValid ? (
                                                <Check style={{ color: "green", marginRight: 8 }} />
                                            ) : (
                                                <Close style={{ color: "red", marginRight: 8 }} />
                                            )}
                                            {requirement.text}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>*Confirm Password</Typography>
                        <TextField
                            placeholder="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={password !== confirmPassword && confirmPassword !== ""} // Show error if passwords do not match
                            helperText={
                                password !== confirmPassword && confirmPassword !== "" ? "Passwords do not match" : ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                        >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />

                        <FormControlLabel
                            sx={{
                                marginTop: "10px",
                            }}
                            label={
                                <Typography>
                                    <Typography sx={{ mr: 0.5, float: "left" }}>*I Agree</Typography>
                                    <Link exact to="/login" style={{ fontWeight: "700", color: "#000" }}>
                                        Terms and Conditions & Privacy Policy
                                    </Link>
                                </Typography>
                            }
                            control={
                                <Checkbox
                                    onChange={(e) => setTermsCondition(e.target.checked)}
                                    checked={termsCondition}
                                />
                            }
                        />
                        <br />
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={registerButtonHandler}
                            fullWidth
                            sx={{
                                mt: 1,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor: "#481BEB",
                            }}
                            disabled={isAllConditionMet}
                        >
                            {buttonLoading && <CircularProgress size={25} />}
                            {!buttonLoading && "Register with Us"}
                        </Button>
                        <Typography sx={{ pt: 2 }}>
                            <Typography sx={{ mr: 0.5, float: "left" }}>Already have an account?</Typography>
                            <Link exact to="/login" style={{ color: "rgba(0, 74, 173, 1)", textDecoration: "none" }}>
                                Login
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </ThemeProvider>
    );
};

export default Register;
